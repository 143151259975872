import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { Button, Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
const AuditionsList = () => {
    const [Data, SetData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/accounts/auditions/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/auditions/?page=${currentPage}&page_size=${RowPerPage}`);
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const handleRowClick = (row) => {
        console.log(row)
        navigate(`/dashboard/masters/Auditions/${row}/edit`)
    }
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false);
        setFormdata({})
    }
    const handleShow = () => {
        setShow(true);
    }
    const [AudiId, SetAudiId] = useState(null);
    const [formData, setFormdata] = useState({});
    const formSubmit = (Data, setFieldErrors) => {
        console.log(formData, "jdshf", Data)
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const Datatosend = {
            ...Data,
            start_date: formattedDate
        }
        axiosConfig.post(`/accounts/auditions/?profile_id=${Datatosend.profile_id}`, Datatosend).then(res => {
            console.log(res)
            handleClose()
            SetAudiId(res.data.id);
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    function formatDate(inputDate) {
        if (!inputDate) return '';
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Id",
            selector: "id",
            cell: row => <>AD{row.id}</>,
            sortable: true
        },
        {
            name: "Banner Name",
            selector: "banner_name",
            cell: row => row.banner_name ? row.banner_name : "--",
            sortable: true
        },
        {
            name: "Project Type",
            selector: "project_type",
            cell: row => row.project_type,
            sortable: true
        },
        {
            name: "Contact Name",
            selector: "contact_name",
            cell: row => <div className="d-flex flex-column">
                <span>{row.contact_name}</span>
                <span>({row.contact_mobile_no})</span>
                <span>{row.contact_designation}</span>
            </div>,
            sortable: true
        },
        // {
        //     name: "Mobile No",
        //     selector: "contact_mobile_no",
        //     cell: row => row.contact_mobile_no,
        //     sortable: true
        // },
        // {
        //     name: "Contact Designation",
        //     selector: "contact_designation",
        //     cell: row => row.contact_designation,
        //     sortable: true
        // },
        {
            name: "Start date",
            selector: "start_date",
            cell: row => formatDate(row.start_date),
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    // catedit(row.id)
                    // setModalTitle("Edit Item");
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/accounts/auditions/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4">
                                <MdOutlineRemoveRedEye className="dashboard-tabe-data-actions" onClick={() => handleRowClick(row.id)} />
                            </div>
                            {/* <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div> */}
                        </div>

                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <MarketingNavbarComponent />
                    <div className="childnav-content">
                        <div className="d-flex align-items-center">
                            <div className="col-4 input-cat-with-icon">
                                <span className="icon-product"><BiSearch /></span>
                                <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                            </div>
                            <div className="cat-button">
                                <div className="btn" onClick={handleShow}>
                                    + Add New
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                // onSort={handleSort}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation={`/dashboard/masters/Auditions/${AudiId}/edit`} />
                }
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
                <Modal
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Create Audition
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormParser modelObject={Masters.Audition} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle="Create Audition" button="btn-block" />
                    </Modal.Body>
                </Modal>

            </DashboardLayout >
        </>
    )
}
export { AuditionsList }