import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, DeletePopupModal, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json"
import { MdOutlinePhone } from "react-icons/md"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AuditionRequirements } from "./AudiRequirements";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { Card, Row, Col, Modal } from 'react-bootstrap';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { AuditionApplications } from "./AuditionApplications";

const AuditionRequirement = ({ Code }) => {
    let { id } = useParams();
    console.log(id, "iddddd")
    const navigate = useNavigate();
    const [key, setKey] = useState('Info');
    const [readOnly, setReadOnly] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [Data, SetData] = useState({});
    const [ApplicationData, SetApplicationData] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(30);
    const [Message, setMessage] = useState("")
    const [requirementShow, setRequirementShow] = useState(false)
    const [requirementData, setRequirementData] = useState([])
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Applications, setapplications] = useState(false)
    const [RequireId, setRequireId] = useState(null)
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            SetData({
                ...res.data,
            })
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }
    console.log(Data)
    const GetAuditionList = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`accounts/audition-applications/?page=${currentPage}&page_size=${RowPerPage}&profile=&audition=${id}&application_status=`)
            console.log(responce.data)
            setTotalRows(responce.data.count)
            const profiles = responce.data.results.map(item => {
                const profile = item.profile;
                profile.Status = `${item.application_status}`;
                return profile
            });
            console.log(profiles)
            SetApplicationData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const formSubmit = (formData, setFieldErrors) => {
        console.log(Data, "data", formData, key)
        let OrderTopost;
        if (key === "Status") {
            OrderTopost = {
                approval_status: formData?.approval_status,
                requirement_status: formData?.requirement_status
            };
        } else {
            OrderTopost = {
                ...formData,
                audition: Data?.audition?.id,
                approval_status: "requested",
            };
        }

        axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, OrderTopost).then(res => {
            console.log(res)
            GetData()
            setMessage("Requirement Updated SuccessFully!")
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "profile") {
            navigate(`/dashboard/users/${link}/edit`)
        }
        if (type === "back") {
            navigate(`/dashboard/masters/Audition-requirements/`)
        }
    }
    const HandleEdit = (id) => {
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            SetData({
                ...res.data,
            })
            setRequirementShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = async (id) => {
        await axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(() => {
            SetAlertDeletePopupType("error")
            setDeletepopupType("success")
            GetData();
        }).catch((error) => {
            setDeletepopupType("error")
            SetAlertDeletePopupType("error")
            console.log(error)
        })
    }
    const handlShow = (id) => {
        setapplications(true)
        setRequireId(id)
    }
    const handleClose = () => {
        setapplications(false)
        setRequireId(null)
    }
    useEffect(() => {
        GetData()
        GetAuditionList()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="main-container">
                    <div className="content-area">
                        <div className="client-options">
                            <BiArrowBack size={30} onClick={() => handleNavigate("", "back")} className="option-icon" />
                        </div>
                        <div className="client-info">
                            <div className="contact-detile">
                                Id : <div className="data-text">AD{Data.audition?.id}</div>
                            </div>
                            <div className="contact-detile">
                                Banner Name : <div className="data-text">{Data.audition?.banner_name}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Name : <div className="data-text">{Data.audition?.contact_name}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Designation Name : <div className="data-text">{Data.audition?.contact_designation}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Mobile No : <a href={`tel:+${Data.audition?.contact_mobile_no}`} className="data-text">{Data.audition?.contact_mobile_no}</a>
                            </div>
                            <div className="contact-detile">
                                Start Date : <div className="data-text">{Data.audition?.start_date}</div>
                            </div>
                        </div>
                        <div className="client-form">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                            // className="mb-3"
                            >
                                <Tab eventKey="Info" title="Info">
                                    <div className="projects-container">
                                        <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <Card className="bax-shadow" style={{ position: "relative" }}>
                                                <Card.Body>
                                                    <Card.Title className="card-title-custom">Role - {Data.character}</Card.Title>
                                                    <Card.Text>
                                                        <div className="applictn-dtls">
                                                            {Data.gender && <div className="apply-content">
                                                                Gender : <span className="apply-data"> {Data.gender}</span >
                                                            </div>}
                                                            {Data.from_age && <div className="apply-content">
                                                                Age : <span className="apply-data"> {Data.from_age} - {Data.to_age} years</span>
                                                            </div>}
                                                            {Data.budget && <div className="apply-content">
                                                                Budget : <span className="apply-data"> {Data.budget ? Data.budget : 0}</span>
                                                            </div>}
                                                            {Data.no_of_requirements && <div className="apply-content">
                                                                No. of Positions : <span className="apply-data"> {Data.no_of_requirements}</span>
                                                            </div>}
                                                            {Data?.loc_lang?.languages && <div className="apply-content">
                                                                Preffered Languages: <span className="apply-data"> {Data?.loc_lang?.languages}</span>
                                                            </div>}
                                                            {Data?.loc_lang?.state && <div className="apply-content">
                                                                Preffered Location: <span className="apply-data"> {Data?.loc_lang?.state}</span>
                                                            </div>}
                                                            {Data.specification && <div className="apply-content">
                                                                Specification: <span className="apply-data"> {Data.specification}</span>
                                                            </div>}
                                                        </div>
                                                    </Card.Text>
                                                    <Card.Footer className="text-center" style={{ background: "none", borderTop: "none", paddingLeft: '0px' }}>
                                                        <button className="btn btn-info w-50" onClick={() => HandleEdit(id)}>Edit</button>
                                                        <button className="btn btn-secondary w-50" onClick={() => DeleteAlert(Data.id)}>Delete</button>
                                                        <button type="button" className="btn btn-primary w-100" onClick={() => handlShow(Data.id)}>View Applications({Data.applications})</button>
                                                    </Card.Footer>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        {requirementShow &&
                                            <Modal
                                                size="lg"
                                                show={requirementShow}
                                                onHide={() => {
                                                    setRequirementShow(false)
                                                }}
                                                aria-labelledby="example-modal-sizes-title-lg"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title id="example-modal-sizes-title-lg">
                                                        Edit Audition Requirements
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {/* <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={AudiRequirements} error={Error} /> */}
                                                    <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                                                </Modal.Body>
                                            </Modal>
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey="Status" title="Status">
                                    <div className="projects-container">
                                        <FormParser modelObject={Masters.RequirementStatus} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message={Message} error={error} />
                }
                {AlertDeletePopupType === "success" &&
                    <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                }
                {Applications &&
                    <AuditionApplications Applications={Applications} setapplications={setapplications} handleClose={handleClose} requirement={RequireId} />
                }
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout >
        </>
    )

}
export { AuditionRequirement }