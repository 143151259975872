import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import axiosConfig from "../Service/axiosConfig";
import { useNavigate } from 'react-router-dom';

const AuditionApplications = ({ Applications, handleClose, requirement }) => {
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(30);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({ gender: '', role: '', search: '' });
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (Applications) {
            fetchAuditionList();
        }
    }, [Applications, currentPage, rowPerPage, filterData]);

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchAuditionList = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`accounts/audition-applications/`, {
                params: {
                    page: currentPage,
                    page_size: rowPerPage,
                    audition_requirement: requirement,
                    gender: filterData.gender,
                    role: filterData.role,
                    search: filterData.search,
                    application_status: ''
                }
            });
            setTotalRows(response.data.count);
            setData(response.data.results);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await axiosConfig.get(`/masters/role-24-frames/`, { params: { page_size: 5 } });
            setRoles(response.data.results);
        } catch (error) {
            console.error(error);
        }
    };

    const handleNavigate = (link, type) => {
        if (type === "profile") {
            window.open(`/dashboard/users/${link}/edit`, '_blank');
        } else {
            navigate(`/auditions`);
        }
    };
    const genderOptions = [
        { value: '', label: '-Select-' },
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'both', label: 'Both' },
        { value: 'other', label: 'Other' }
    ];
    const handleSelectChange = (selectedOption, name) => {
        setFilterData(prevState => ({
            ...prevState,
            [name]: selectedOption?.value || ""
        }));
    };

    const handleSearchChange = (e) => {
        setFilterData(prevState => ({
            ...prevState,
            search: e.target.value
        }));
    };

    const calculateAge = dob => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * rowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Image",
            selector: row => (
                <div onClick={() => handleNavigate(row?.profile?.id, "profile")}>
                    <img className="profile-pic" src={row.profile?.profile_pic || ''} alt="profile pic" />
                </div>
            ),
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => <div onClick={() => handleNavigate(row.profile?.id, "profile")}>{row.profile?.first_name}</div>,
            sortable: true
        },
        {
            name: "HCC ID",
            selector: "hcc_id",
            cell: row => row.profile?.hcc_id,
            sortable: true
        },
        {
            name: "Mobile No.",
            selector: "mobile_number",
            cell: row => row.profile?.mobile_number,
            sortable: true
        },
        {
            name: "Role",
            selector: "designation",
            cell: row => row.profile?.designation || "----",
            sortable: true
        },
        {
            name: "Age",
            selector: "dob",
            cell: row => <div>{calculateAge(row.profile?.dob)}</div>,
            sortable: true
        },
        {
            name: "Gender",
            selector: "gender",
            cell: row => <div>{row.profile?.gender}</div>,
            sortable: true
        },
        {
            name: "Location",
            selector: "location",
            cell: row => <div>{row.profile?.present_state}-{row.profile?.present_city}</div>,
            sortable: true
        },
        {
            name: "Status",
            selector: "application_status",
            cell: row => row.application_status,
            sortable: true
        }
    ];

    return (
        <Modal
            size="lg"
            show={Applications}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
            className="modal-size-craft"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>List Of Applicants</Modal.Title>
            </Modal.Header>
            <Modal.Body className="success-icon">
                <div>
                    <b>Received Applications</b> ({totalRows})
                </div>
                {/* <div className="row">
                    <Select
                        className="col-4"
                        options={genderOptions.map((res) => (
                            { "value": res.value, "label": res.label }
                        ))
                        }
                        placeholder="Gender"
                        value={genderOptions.map(res => ({ "value": res.value, "label": res.label })).find(res => res.value === filterData.gender)}
                        onChange={selectedOption => handleSelectChange(selectedOption, "gender")}
                    />
                    <Select
                        className="col-4"
                        options={roles.map(role => ({ value: role.id, label: role.frame_name }))}
                        placeholder="Select Role"
                        value={roles.map(res => ({ "value": res.value, "label": res.label })).find(res => res.value === filterData.role)}
                        onChange={selectedOption => handleSelectChange(selectedOption, "role")}
                    />
                    <div className="form-control d-flex align-items-center w-25">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={filterData.search}
                            onChange={handleSearchChange}
                            autoFocus
                        />
                        <div className="search-icon1">
                            <BiSearch size={20} />
                        </div>
                    </div>
                </div> */}
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationTotalRows={totalRows}
                    paginationPerPage={rowPerPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={setCurrentPage}
                    onChangeRowsPerPage={setRowPerPage}
                    paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                    customStyles={{
                        rows: { style: {} },
                        cells: { style: { justifyContent: "center" } },
                        headCells: {
                            style: {
                                color: "#212529",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "28px",
                                justifyContent: "center"
                            }
                        }
                    }}
                    progressPending={loading}
                    progressComponent={<div className="loader-container"><img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." /></div>}
                />
            </Modal.Body>
        </Modal>
    );
};

export { AuditionApplications };
